<template>
<div>
  <b-input v-model="surveyId"/>
  <div id="surveyCreator" />
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("survey-builder");
import { db } from "@/services/db";
import { SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { loadObj } from '../../services/dbutils';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true
};

const defaultJson = {
  pages: [{
    name: "Name",
    elements: [{
      name: "FirstName",
      title: "Enter your first name:",
      type: "text"
    }, {
      name: "LastName",
      title: "Enter your last name:",
      type: "text"
    }]
  }]
};

export default {
  name: "survey-creator",
  data() {
    return {
      surveyId: "survey-test-01",
      creator: null,
    }
  },
  async mounted() {
    this.surveyId = this.$route.params.surveyid || this.surveyId;
    if (this.surveyId)
      await this.loadSurvey();
  },
  methods: {
    async loadSurvey() {
      this.creator = new SurveyCreator(creatorOptions);
      this.creator.text = JSON.stringify(await loadObj(db.collection("LiveSurveys").doc(this.surveyId)) || defaultJson);
      log.log("Loading survey", this.creator.JSON);
      this.creator.saveSurveyFunc = this.saveSurvey;
      this.creator.render("surveyCreator");
    },
    async saveSurvey(saveNum, callback) { 
      log.log(this.creator.JSON, saveNum);
      try {
        await db.collection("LiveSurveys").doc(this.surveyId).set(this.creator.JSON);
        callback(saveNum, true);
      } catch (e) {
        callback(saveNum, false);
      }
    }
  }
};

</script>
<style>
#surveyCreator {
  height: 100vh;
  width: 100vw;
}
.svc-creator__banner {
  display:none;
}
</style>